import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material'
import { useAuth } from 'context'
import * as controllers from 'controllers'
import { PersonDto, WorkOrderReleaseTaskTimeEntryDto } from 'dtos'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { hhMmToDecimal } from 'utils'
import * as yup from 'yup'
import { ExplanationAccordion } from './_template/accordion'
import { specialIdentifiers } from '../constants'

const workOrderReleaseTaskTimeEntryValidationSchema = yup.object({
  databaseDesigner: yup.object().required('Please select a Database Designer.'),
  databaseDesignerEstimatedHours: yup.number().required('Estimated Hours required.'),
  frontEndDeveloper: yup.object().required('Please select a Front End Developer.'),
  frontEndDeveloperEstimatedHours: yup.number().required('Estimated Hours required.'),
  backEndDeveloper: yup.object().required('Please select a Back End Developer.'),
  backEndDeveloperEstimatedHours: yup.number().required('Estimated Hours required.'),
  descriptionOfWorkDone: yup
    .string()
    .max(2_000, 'Work Done cannot be more than 2,000 characters.')
    .required('Work Done is required.'),
  hoursWorked: yup
    .number()
    .typeError('Hours Worked must be a number.')
    .max(24, 'Hours Worked cannot be more than 24.00.')
    .required('Hours Worked is required.'),
  hoursStillNeeded: yup
    .number()
    .typeError('Hours Still Needed must be a number.')
    .max(999, 'Hours Still Needed cannot be more than 999.00.')
    .when(['taskCanBeClosed', 'workOrderReleaseTask.workOrderReleaseTaskType.specialIdentifier'], (values, schema) => {
      const taskCanBeClosed = values[0]
      const specialIdentifier = values[1]

      if (taskCanBeClosed) {
        return schema.nullable()
      } else {
        if (
          specialIdentifier !== specialIdentifiers.workOrderReleaseTaskTypes.Management &&
          specialIdentifier !== specialIdentifiers.workOrderReleaseTaskTypes.NetworkClientSupport &&
          specialIdentifier !== specialIdentifiers.workOrderReleaseTaskTypes.CallCenter
        ) {
          return schema.min(0.01, 'Hours Still Needed cannot be less than 0.01.').required('Hours Still Needed is required.')
        } else {
          return schema
            .test('is-zero', 'Hours Still Needed must be zero', value => value === 0)
            .required('Hours Still Needed is required.')
        }
      }
    }),
  taskCanBeClosed: yup
    .boolean()
    .when(
      [
        'workOrderReleaseTask.workOrderReleaseTaskType.specialIdentifier',
        'workOrderReleaseTask.estimatedHours',
        'workOrderReleaseTask.hoursToDate',
        'hoursWorked'
      ],
      (values, schema) => {
        const specialIdentifier = values[0]
        const estimatedHours = values[1]
        const hoursToDate = values[2]
        const hoursWorked = values[3]

        if (
          specialIdentifier === specialIdentifiers.workOrderReleaseTaskTypes.VacationTime &&
          estimatedHours - hoursToDate - (hoursWorked ?? 0) <= 0
        ) {
          return schema.required('Must be closed').oneOf([true], 'Must be closed')
        }

        return schema.nullable()
      }
    )
})

interface WorkOrderReleaseTaskTimeEntryAddEditDialogProps {
  initialValues: WorkOrderReleaseTaskTimeEntryDto
  isLoading?: boolean
  onClose: () => void
  onSave: (values: WorkOrderReleaseTaskTimeEntryDto) => void
  open: boolean
}

export default function WorkOrderReleaseTaskTimeEntryAddEditDialog({
  initialValues,
  isLoading,
  onClose,
  onSave,
  open
}: WorkOrderReleaseTaskTimeEntryAddEditDialogProps) {
  const {
    user,
    permissions: { TIME_ENTRIES_ADD_EDIT, CAN_LOG_TIME_FOR_OTHER_USERS, VOID_PROCESS }
  } = useAuth()

  const [teamMembers, setTeamMembers] = useState<PersonDto[]>([])
  const [_initialValues, setInitialValues] = useState<WorkOrderReleaseTaskTimeEntryDto>(initialValues)

  useEffect(() => {
    controllers.getPeople({ page: 0, pageSize: 2000, permission: 'TASKS', statuses: ['isActive'] }).then(response => {
      setTeamMembers(response.value)
    })
  }, [])

  useEffect(() => {
    if (
      [
        specialIdentifiers.workOrderReleaseTaskTypes.Management,
        specialIdentifiers.workOrderReleaseTaskTypes.NetworkClientSupport,
        specialIdentifiers.workOrderReleaseTaskTypes.CallCenter
      ].includes(initialValues.workOrderReleaseTask?.workOrderReleaseTaskType?.specialIdentifier ?? '')
    ) {
      setInitialValues({ ...initialValues, hoursStillNeeded: 0 })
    } else {
      setInitialValues(initialValues)
    }
  }, [initialValues])

  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={_initialValues}
        validateOnChange
        validateOnBlur
        validationSchema={workOrderReleaseTaskTimeEntryValidationSchema}
        onSubmit={values => {
          onSave(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          validateForm,
          setTouched,
          submitForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{values.id ? 'Edit' : 'Add'} Time Entry</DialogTitle>

                  <Grid item xs={12}>
                    <Typography variant='body1' color='primary'>
                      {values.workOrderReleaseTask?.workOrderRelease?.workOrder?.company?.name +
                        ' - ' +
                        values.workOrderReleaseTask?.workOrderRelease?.workOrder?.project?.name}
                    </Typography>

                    <Typography variant='body1' color='primary'>
                      {values.workOrderReleaseTask?.workOrderRelease?.releaseTitle}{' '}
                      {values.workOrderReleaseTask?.workOrderRelease?.releaseNumber
                        ? `- ${values.workOrderReleaseTask?.workOrderRelease.releaseNumber}`
                        : ''}
                    </Typography>

                    <Typography variant='body1' color='primary'>
                      {values.workOrderReleaseTask?.workOrderReleaseTaskType?.name} -{' '}
                      {values.workOrderReleaseTask?.workToBeDone}
                    </Typography>
                    <Typography variant='body1' color='primary'>
                      {values.workOrderReleaseTask?.hoursStillNeeded} Hours Still Needed
                    </Typography>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <LabelField
                      label='Company'
                      value={values.workOrderReleaseTask?.workOrderRelease?.workOrder?.company?.name ?? ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LabelField
                      label='Work Order'
                      value={
                        '#' +
                        values.workOrderReleaseTask?.workOrderRelease?.workOrder?.workOrderNumber +
                        ' - ' +
                        values.workOrderReleaseTask?.workOrderRelease?.workOrder?.project?.name
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LabelField
                      label='Release'
                      value={values.workOrderReleaseTask?.workOrderRelease?.workOrderReleaseType?.name ?? ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LabelField
                      label='Release Title'
                      value={values.workOrderReleaseTask?.workOrderRelease?.releaseTitle ?? ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LabelField label='Task Type' value={values.workOrderReleaseTask?.workOrderReleaseTaskType?.name ?? ''} />
                  </Grid>

                  <Grid item xs={12}>
                    <LabelField label='Work To Be Done' value={values.workOrderReleaseTask?.workToBeDone ?? ''} />
                  </Grid> 

                  <Grid item xs={12}>
                    <LabelField label='Estimated Hours' value={values.workOrderReleaseTask?.estimatedHours + '' ?? ''} />
                  </Grid>*/}

                  {CAN_LOG_TIME_FOR_OTHER_USERS && (
                    <Grid item xs={12}>
                      <Autocomplete
                        disabled={isLoading || isSubmitting}
                        disableCloseOnSelect
                        getOptionLabel={teamMember => teamMember.firstName + ' ' + teamMember.lastName}
                        onBlur={handleBlur}
                        onChange={(_e, value) => {
                          setFieldValue('workDoneBy', value)
                        }}
                        options={teamMembers.map(teamMember => ({
                          id: teamMember.id,
                          firstName: teamMember.firstName,
                          lastName: teamMember.lastName
                        }))}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={Boolean(touched.workDoneBy && errors.workDoneBy)}
                            helperText={touched.workDoneBy && (errors.workDoneBy as string)}
                            label='Work Done By'
                          />
                        )}
                        value={
                          values.workDoneBy
                            ? {
                                id: values.workDoneBy.id,
                                firstName: values.workDoneBy.firstName,
                                lastName: values.workDoneBy.lastName
                              }
                            : {
                                id: user?.id,
                                firstName: user?.firstName,
                                lastName: user?.lastName
                              }
                        }
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || !TIME_ENTRIES_ADD_EDIT}
                      error={Boolean(touched.workDate && errors.workDate)}
                      helperText={touched.workDate && errors.workDate}
                      InputLabelProps={{ shrink: true }}
                      label='Work Date'
                      name='workDate'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='date'
                      value={values.workDate}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.descriptionOfWorkDone && errors.descriptionOfWorkDone)}
                      helperText={touched.descriptionOfWorkDone && errors.descriptionOfWorkDone}
                      label='Work Done'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='descriptionOfWorkDone'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.descriptionOfWorkDone || ''}
                    />
                  </Grid>

                  {values.workOrderReleaseTask?.workOrderReleaseTaskType?.specialIdentifier ===
                    specialIdentifiers.workOrderReleaseTaskTypes.VacationTime && (
                    <Grid item xs={12}>
                      <Typography variant='body1' color='primary'>
                        Vaction Time Available:{' '}
                        {(values.workOrderReleaseTask?.estimatedHours ?? 0) - (values.workOrderReleaseTask?.hoursToDate ?? 0)}{' '}
                        hours
                      </Typography>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading}
                      error={Boolean(touched.hoursWorked && errors.hoursWorked)}
                      helperText={touched.hoursWorked && errors.hoursWorked}
                      label='Hours Worked'
                      name='hoursWorked'
                      onBlur={e => {
                        handleBlur(e)
                        try {
                          const decimalHours = hhMmToDecimal(e.target.value)
                          setFieldValue('hoursWorked', decimalHours)
                        } catch (error) {
                          console.error()
                          setFieldValue('hoursWorked', '')
                        }
                      }}
                      onChange={handleChange}
                      type='text'
                      value={values.hoursWorked}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.taskCanBeClosed}
                          disabled={isLoading}
                          name='taskCanBeClosed'
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e)

                            if (e.target.checked) {
                              setFieldValue('hoursStillNeeded', undefined)
                            }
                          }}
                          value={values.taskCanBeClosed}
                        />
                      }
                      label='Task is Complete'
                    />

                    {touched.taskCanBeClosed && errors.taskCanBeClosed && (
                      <FormHelperText error>{errors.taskCanBeClosed}</FormHelperText>
                    )}
                  </Grid>

                  {TIME_ENTRIES_ADD_EDIT && VOID_PROCESS && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.doNotAutomaticallyOpenNextTask}
                            disabled={isLoading}
                            name='doNotAutomaticallyOpenNextTask'
                            onBlur={handleBlur}
                            onChange={e => {
                              setFieldValue('doNotAutomaticallyOpenNextTask', !values.doNotAutomaticallyOpenNextTask)
                            }}
                            value={values.doNotAutomaticallyOpenNextTask}
                          />
                        }
                        label='Do Not Automatically Open Next Task'
                      />
                    </Grid>
                  )}

                  {!values.taskCanBeClosed && (
                    <Grid item xs={12}>
                      <TextField
                        disabled={isLoading}
                        error={Boolean(touched.hoursStillNeeded && errors.hoursStillNeeded)}
                        helperText={touched.hoursStillNeeded && errors.hoursStillNeeded}
                        label='Hours Still Needed'
                        name='hoursStillNeeded'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='number'
                        value={values.hoursStillNeeded}
                      />
                    </Grid>
                  )}

                  {values.taskCanBeClosed &&
                    values.workOrderReleaseTask?.workOrderReleaseTaskType?.specialIdentifier ===
                      specialIdentifiers.workOrderReleaseTaskTypes.DoDesignSpecInternalReview && (
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            name='isApproved'
                            value={values.isApproved}
                            onChange={e => {
                              setFieldValue('isApproved', e.target.value === 'true')
                            }}
                          >
                            <FormControlLabel value='true' control={<Radio checked={values.isApproved} />} label='Approved' />
                            <FormControlLabel
                              value='false'
                              control={<Radio checked={!values.isApproved} />}
                              label='Needs Design Adjustments'
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}

                  {values.taskCanBeClosed &&
                    values.workOrderReleaseTask?.workOrderReleaseTaskType?.specialIdentifier ===
                      specialIdentifiers.workOrderReleaseTaskTypes.GetDesignSpecVerbalApproval && (
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            name='isApproved'
                            value={values.isApproved}
                            onChange={e => {
                              setFieldValue('isApproved', e.target.value === 'true')
                            }}
                          >
                            <FormControlLabel value='true' control={<Radio checked={values.isApproved} />} label='Approved' />
                            <FormControlLabel
                              value='false'
                              control={<Radio checked={!values.isApproved} />}
                              label='Needs Design Adjustments'
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}

                  {values.taskCanBeClosed &&
                    values.workOrderReleaseTask?.workOrderReleaseTaskType?.specialIdentifier ===
                      specialIdentifiers.workOrderReleaseTaskTypes.DoTesting && (
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            name='isApproved'
                            value={values.isApproved}
                            onChange={e => {
                              setFieldValue('isApproved', e.target.value === 'true')
                            }}
                          >
                            <FormControlLabel
                              value='true'
                              control={<Radio checked={values.isApproved} />}
                              label='Release is Ready for Client Review'
                            />
                            <FormControlLabel
                              value='false'
                              control={<Radio checked={!values.isApproved} />}
                              label='Redline Document Needs to be Reviewed'
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}

                  {values.taskCanBeClosed &&
                    values.workOrderReleaseTask?.workOrderReleaseTaskType?.specialIdentifier ===
                      specialIdentifiers.workOrderReleaseTaskTypes.ApproveorDeclineRedline && (
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            name='isApproved'
                            value={values.isApproved}
                            onChange={e => {
                              setFieldValue('isApproved', e.target.value === 'true')
                            }}
                          >
                            <FormControlLabel
                              value='true'
                              control={<Radio checked={values.isApproved} />}
                              label='Release is Ready for Client Review'
                            />
                            <FormControlLabel
                              value='false'
                              control={<Radio checked={!values.isApproved} />}
                              label='Needs Adjustments'
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}

                  {values.taskCanBeClosed &&
                    values.workOrderReleaseTask?.workOrderReleaseTaskType?.specialIdentifier ===
                      specialIdentifiers.workOrderReleaseTaskTypes.GetStagingClientVerbalApproval && (
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            name='isApproved'
                            value={values.isApproved}
                            onChange={e => {
                              setFieldValue('isApproved', e.target.value === 'true')
                            }}
                          >
                            <FormControlLabel
                              value='true'
                              control={<Radio checked={values.isApproved} />}
                              label='Release is Ready for Client Signature'
                            />
                            <FormControlLabel
                              value='false'
                              control={<Radio checked={!values.isApproved} />}
                              label='Needs Adjustments'
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}

                  {values.taskCanBeClosed &&
                    values.workOrderReleaseTask?.workOrderReleaseTaskType?.specialIdentifier ===
                      specialIdentifiers.workOrderReleaseTaskTypes.GetReleaseClientSignature && (
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            name='isApproved'
                            value={values.isApproved}
                            onChange={e => {
                              setFieldValue('isApproved', e.target.value === 'true')
                            }}
                          >
                            <FormControlLabel
                              value='true'
                              control={<Radio checked={values.isApproved} />}
                              label='Deploy to Production'
                            />
                            <FormControlLabel
                              value='false'
                              control={<Radio checked={!values.isApproved} />}
                              label='Mark Release Complete'
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}

                  {values.taskCanBeClosed &&
                    values.workOrderReleaseTask?.workOrderReleaseTaskType?.specialIdentifier ===
                      specialIdentifiers.workOrderReleaseTaskTypes.DoDeveloperKickOffMeeting && (
                      <Grid item xs={12} container spacing={1}>
                        <Grid item xs={6}>
                          <Autocomplete
                            disabled={isLoading || isSubmitting}
                            disableCloseOnSelect
                            disableClearable
                            getOptionLabel={teamMember => teamMember.firstName + ' ' + teamMember.lastName}
                            onBlur={handleBlur}
                            onChange={(_e, value) => {
                              setFieldValue('databaseDesigner', value)
                            }}
                            options={teamMembers.map(teamMember => ({
                              id: teamMember.id,
                              firstName: teamMember.firstName,
                              lastName: teamMember.lastName
                            }))}
                            renderInput={params => (
                              <TextField
                                {...params}
                                error={Boolean(touched.databaseDesigner && errors.databaseDesigner)}
                                helperText={touched.databaseDesigner && (errors.databaseDesigner as string)}
                                label='Create Database Design'
                                name='databaseDesigner'
                              />
                            )}
                            value={
                              values.databaseDesigner
                                ? {
                                    id: values.databaseDesigner.id,
                                    firstName: values.databaseDesigner.firstName,
                                    lastName: values.databaseDesigner.lastName
                                  }
                                : undefined
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={isLoading}
                            error={Boolean(touched.databaseDesignerEstimatedHours && errors.databaseDesignerEstimatedHours)}
                            helperText={touched.databaseDesignerEstimatedHours && errors.databaseDesignerEstimatedHours}
                            label='Estimated Hours'
                            name='databaseDesignerEstimatedHours'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='number'
                            value={values.databaseDesignerEstimatedHours}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Autocomplete
                            disabled={isLoading || isSubmitting}
                            disableCloseOnSelect
                            disableClearable
                            getOptionLabel={teamMember => teamMember.firstName + ' ' + teamMember.lastName}
                            onBlur={handleBlur}
                            onChange={(_e, value) => {
                              setFieldValue('frontEndDeveloper', value)
                            }}
                            options={teamMembers.map(teamMember => ({
                              id: teamMember.id,
                              firstName: teamMember.firstName,
                              lastName: teamMember.lastName
                            }))}
                            renderInput={params => (
                              <TextField
                                {...params}
                                error={Boolean(touched.frontEndDeveloper && errors.frontEndDeveloper)}
                                helperText={touched.frontEndDeveloper && (errors.frontEndDeveloper as string)}
                                label='Do Front End Development'
                                name='frontEndDeveloper'
                              />
                            )}
                            value={
                              values.frontEndDeveloper
                                ? {
                                    id: values.frontEndDeveloper.id,
                                    firstName: values.frontEndDeveloper.firstName,
                                    lastName: values.frontEndDeveloper.lastName
                                  }
                                : undefined
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={isLoading}
                            error={Boolean(touched.frontEndDeveloperEstimatedHours && errors.frontEndDeveloperEstimatedHours)}
                            helperText={touched.frontEndDeveloperEstimatedHours && errors.frontEndDeveloperEstimatedHours}
                            label='Estimated Hours'
                            name='frontEndDeveloperEstimatedHours'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='number'
                            value={values.frontEndDeveloperEstimatedHours}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Autocomplete
                            disabled={isLoading || isSubmitting}
                            disableCloseOnSelect
                            disableClearable
                            getOptionLabel={teamMember => teamMember.firstName + ' ' + teamMember.lastName}
                            onBlur={handleBlur}
                            onChange={(_e, value) => {
                              setFieldValue('backEndDeveloper', value)
                            }}
                            options={teamMembers.map(teamMember => ({
                              id: teamMember.id,
                              firstName: teamMember.firstName,
                              lastName: teamMember.lastName
                            }))}
                            renderInput={params => (
                              <TextField
                                {...params}
                                error={Boolean(touched.backEndDeveloper && errors.backEndDeveloper)}
                                helperText={touched.backEndDeveloper && (errors.backEndDeveloper as string)}
                                label='Do Back End Development'
                                name='backEndDeveloper'
                              />
                            )}
                            value={
                              values.backEndDeveloper
                                ? {
                                    id: values.backEndDeveloper.id,
                                    firstName: values.backEndDeveloper.firstName,
                                    lastName: values.backEndDeveloper.lastName
                                  }
                                : undefined
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={isLoading}
                            error={Boolean(touched.backEndDeveloperEstimatedHours && errors.backEndDeveloperEstimatedHours)}
                            helperText={touched.backEndDeveloperEstimatedHours && errors.backEndDeveloperEstimatedHours}
                            label='Estimated Hours'
                            name='backEndDeveloperEstimatedHours'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='number'
                            value={values.backEndDeveloperEstimatedHours}
                          />
                        </Grid>
                      </Grid>
                    )}
                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading}
                      error={Boolean(touched.travelMiles && errors.travelMiles)}
                      helperText={touched.travelMiles && errors.travelMiles}
                      label='Mileage'
                      name='travelMiles'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      value={values.travelMiles}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} mt={2}>
                  <ExplanationAccordion>
                    Work Date is disabled and defaults to todays date, unless the user has Time Entry Add/Edit permissions.
                    ||Estimated Hours displays the estimated hours, otherwise it will display Needs Estimation until the task
                    has estimated hours.
                  </ExplanationAccordion>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button color='inherit' disabled={isLoading} onClick={onClose} variant='text'>
                  CANCEL
                </Button>

                <LoadingButton loading={isLoading} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
